.landing-page {
  background: #f8f7f7 url(/assets/img/nsm-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  .landing-content {
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    justify-content: center;

    @include breakpoint($mobile) {
      display: block;
      margin-top: 50px;
    }
  }
}

.container-area {
  margin: 0 auto;
  max-width: 370px;
  padding-left: 15px;
  padding-right: 15px;
}

.container-fluid {
  .container-area {
    //max-width: 340px;
    padding: 0;
  }
}

.container-area.container-large {
  max-width: 530px;
}

.container-area.container-full {
  width: 530px;
}

.container-area-lg {
  margin: 0 auto;
  max-width: 680px;
  padding-left: 15px;
  padding-right: 15px;
}

.select-frame {
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 300px;
  position: relative;

  &-inline {
    display: inline-block;
  }

  @include breakpoint($mobile) {
    margin-bottom: 70px;
    margin-top: 5px;
  }
}

.fraud-notice {
  text-transform: uppercase;
}

.retro-datepicker {
  padding-top: 40px;
}
