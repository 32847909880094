@import "../bootstrap-variables";
@import "mixins";

* {
  max-height: 1000000px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  margin: 0;
  // Using viewport-cover=fit may cause the content to be obscured at the top.
  // This can be handled in CSS using the new safe-area-inset-* constants in iOS 11.
  // stylelint-disable-next-line
  margin-bottom: constant(safe-area-inset-bottom);
  // stylelint-disable-next-line
  margin-bottom: env(safe-area-inset-bottom);
  min-width: $base-min-width;
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
}

//- HTML5 display-role reset for older browsers
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
  margin: 0;
  padding: 0;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  outline: none;
  vertical-align: top;
}

ul,
ol {
  @extend %listreset;
}

form,
fieldset {
  border-style: none;
  margin: 0;
  padding: 0;
}

select {
  -moz-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }
}

a {
  text-decoration: none;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
  }
}

button::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border: 0;
  padding: 0;
}

// stylelint-disable-next-line
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input[type="search"],
input[type="email"] {
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

input[type="email"] {
  background: $color-white url("/assets/img/icons/email-icon.png") no-repeat 9px 12px;
  background-size: 21px !important;
  padding: 6px 12px 7px 37px;
}

label {
  color: $color-tundora;
  cursor: pointer;
  font-size: 14px;
  font-weight: 900;
  line-height: 14px;
  margin: 0 0 5px;
  padding: 0;
  text-align: left;
}

.checkbox {
  label {
    font-weight: normal;
    line-height: initial;
  }

  input[type="checkbox"] {
    margin-top: 2px !important;
  }
}

// stylelint-disable-next-line
a {
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    text-decoration: none;
  }
}

.h1 {
  font-size: 28px;
  font-weight: 900;
  margin-bottom: 20px;
}

.h2 {
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 20px;
}

.h6 {
  color: $color-dusty-gray;
  font-size: 14px;
  text-align: center;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0 0 20px;
}

// temporary solution for demo
//TODO: Vlad R remove it or update
.modal-open {
  .btn.back-button,
  .hidden-btn,
  footer {
    z-index: -1 !important;
  }
}
