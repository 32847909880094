#cc_c2a {
  .country_btn,
  .mainbar {
    display: none;
  }

  &.c2a_light {
    z-index: 99999;
  }
}
