.row {
  &.display-flex {
    display: flex;
    flex-wrap: wrap;

    > [class*="col-"] {
      display: flex;
      flex-direction: column;
    }
  }
}

// The classes below designed to prevent writing additional code
// for the flex grid, basically they're take from the bootstrap 4
.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-items {
  &-center {
    align-items: center;
  }
}

.justify-content {
  &-center {
    justify-content: center;
  }
}
