.modal-content {
  .confirm-mta-modal & {
    width: 385px;
  }
}

.modal {
  overflow-y: auto;

  .modal-header {
    border-bottom: none;
    padding: 30px 0 6px;

    h4 {
      color: $color-dusk;
      font-size: 28px;
      font-weight: 900;
    }
  }

  .modal-content {
    border-radius: 5px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    padding: 5px;

    position: relative;
    text-align: center;
    width: 340px;

    @include breakpoint($mobile) {
      width: 100%;
    }

    .close {
      margin: 0;

      position: absolute;
      right: 12px;
      top: 10px;
    }
  }

  .modal-body {
    padding-bottom: 12px;

    .title {
      color: $color-dusk;
      display: block;
      font-size: 18px;
      font-weight: 900;
      line-height: 1.33;
      margin-bottom: 16px;
    }

    .body {
      color: $color-dove-gray;
      font-size: 14px;
      text-align: center;
    }
  }

  .modal-footer {
    border-top: 0;
    padding: 15px 15px 27px;
    text-align: center;
  }

  &.modal-cancel-policy {
    .modal-content {
      width: 500px;
    }

    .modal-body {
      h3 {
        margin-bottom: 11px;
      }

      .btn-text {
        margin-bottom: 0;
      }

      .buttons {
        margin-top: 15px;
      }
    }
  }
}

.modal-backdrop {
  background-color: $color-dusk;
  height: 100vh;
  opacity: 0.7;
}

.modal-markdown {
  .modal-header {
    border-bottom: none;
    padding-bottom: 20px;
  }

  .modal-dialog {
    max-width: 800px;
    width: 80%;

    @include breakpoint(768px) {
      width: auto;
    }
  }

  // stylelint-disable-next-line
  .modal-content {
    width: 100%;
  }

  // stylelint-disable-next-line
  .modal-body {
    padding: 0 30px 15px;
    text-align: left;

    @include breakpoint($mobile) {
      padding: 0 20px 15px;
    }
  }

  ol,
  ul {
    list-style: initial;
    margin: 0 0 3rem 2.2rem;
  }

  h1 {
    font-size: 34px;
    margin: 2rem 0 20px;
    text-align: center;
  }

  h2 {
    font-size: 29px;
    margin-bottom: 2rem;
    margin-top: 4rem;
    text-align: center;
  }

  // stylelint-disable-next-line
  h3 {
    font-size: 24px;
    margin: 3rem 0 1.2rem 0;
    text-align: left;
  }

  p {
    margin: 0 0 1.5rem;
  }
}

// stylelint-disable-next-line
.modal-dialog {
  &.notes-modal {
    .modal-content {
      max-width: 600px;
      width: 100%;
    }
  }
}

.disagree-modal {
  .img-area {
    margin: 0 0 30px;
    text-align: center;

    .img {
      @include inline-block(top);
      width: 57px;
    }
  }
}
