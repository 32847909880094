// Colors
$color-broom: #ffe414;
$color-sandy-brown: #f48365;
$color-orange: #ff6813;
$color-flame-pea: #df5847;
$color-apple-blossom: #a94442;
$color-kraiola-mahogany: #cd4a4c;
$color-red: #f00;
$color-greeny-keppel: #43b392;
$color-robin-egg-blue: #00b5c7;
$color-dusk: #405772;
$color-rhino: #30415f;
$color-pickled-bluewood: #2e3f51;

$color-white: #fff;
$color-alabaster: #f8f8f8;
$color-wild-sand: #f6f6f6;
$color-vista-white: #fcf9f8;
$color-concrete: #f2f2f2;
$color-seashell: #f1f1f1;
$color-mercury: #e7e7e7;
$color-ghost: #c5ccd4;
$color-pale-slate: #cbc8cb;
$color-alto: #999;
$color-silver: #ccc;
$color-iron: #ddd;
$color-dusty-gray: #9b9b9b;
$color-boulder: #777;
$color-dove-gray: #696969;
$color-emperor: #555;
$color-tundora: #464646;
$color-ebony-clay: #26263c;
$color-mine-shaft: #333;
$color-black: #000;
