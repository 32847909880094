input[type="text"],
input[type="email"],
input[type="phone"],
input[type="password"] {
  border-width: 1px;

  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.input-group-addon {
  border-width: 2px;
  padding: 6px 0 6px 12px;
}

.form-control {
  font-family: $font-ave-roman;
}

.input-group .form-control {
  border-left: 0;
}
