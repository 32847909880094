ul {
  padding-left: 1.5rem;
}

hr {
  background: $color-robin-egg-blue;
  border: none;
  height: 2px;
  width: 80%;
}

hr.mini-hr {
  background: $color-dusty-gray;
  width: 10%;
}

hr.align-center {
  margin-inline-start: auto;
  margin-inline-end: auto;
}

p.success {
  color: $color-orange;
}

.content {
  flex: 1 0 auto;
  position: relative;
}

.main-wrapper {
  align-content: space-between;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  position: relative;

  &.grey .content {
    background-color: $color-vista-white;
  }
}

a {
  text-decoration: none;
}

a:focus {
  outline: 0 !important;
}

.text-mini {
  color: $color-tundora;
  font-size: 16px;
}

.text-confirm {
  color: $color-dove-gray;
  line-height: 1.43;
}

.text-orange {
  color: $color-sandy-brown;
  font-size: 18px;
  font-weight: 900;
}
.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
  text-decoration: none;
}
