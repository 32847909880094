@import "../_bootstrap-variables";
@import "mixins";

// forms
form,
fieldset {
  border-style: none;
  margin: 0;
  padding: 0;
}

input {
  color: $color-black;

  &.form-control {
    color: $color-black;
  }

  &[type="search"] {
    -webkit-appearance: textfield;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &[disabled] {
    color: $color-tundora;
  }
}


select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &.form-control {
    padding-left: 9px;
    padding-right: 19px;
    position: relative;
  }

  &:focus {
    outline: none;
  }
}

.with-dropdown-icon {
  position: relative;

  svg {
    position: absolute;
    right: 6px;
    top: 10px;
  }
}

.with-dp-icon {
  position: relative;

  svg {
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 10px;

    @media (min-width: 350px) {
      right: 25px;
    }
  }
}

textarea {
  resize: vertical;
  vertical-align: top;

  &.form-control {
    max-height: 300px;
    min-height: 167px;
    border: 1px solid #43b392;
  }
}

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
  margin: 0 0 0 -20px;
}

.float-label {
  height: 38px;
  margin-bottom: 37px;
  position: relative;

  label {
    bottom: 5px;
    color: $color-mine-shaft;
    font-size: 12px;
    font-weight: 300;
    left: 0;
    margin: 0;
    position: absolute;
    text-align: left;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease-in-out;
    z-index: 0;
  }

  input,
  textarea {
    background: none;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    //box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2);
    box-shadow: none;
    font-size: $font-size-base;
    height: 36px;
    padding: 11px 0 0;
    z-index: 1;

    &:valid,
    &:focus {
      //box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2) !important;
    }

    // stylelint-disable
    &:valid + label,
    &.active + label,
    &:focus + label,
    & + label {
      // styleling-enable
      color: $color-dusty-gray;
      font-size: 12px;
      -webkit-transform: translate3d(0, -22px, 0);
      transform: translate3d(0, -22px, 0);
    }
  }
}

.form-control {
  color: $color-black;
  line-height: 1rem;

  &.address-control {
    background: $color-white url("/assets/img/icons/adress-icon.png") no-repeat;
    background-position: 8px 9px;
    background-size: 17px;
    padding-left: 33px;
  }
}

.form-group {
  &.secondary-form-group {
    margin-bottom: 42px;

    .form-group {
      margin-bottom: 15px;
    }

    label {
      @include inline-block(top);
      color: $color-dusk;
      line-height: 19px;
      margin-bottom: 13px;
    }
  }

  &.required .control-label:after {
    content:"*";
    color:red;
  }
}

.datepicker-field {
  .form-control {
    padding-right: 30px;

    @media (min-width: 350px) {
      padding-right: 35px;
    }
  }
}

.has-error {
  input {
    &.form-control {
      border-color: $color-apple-blossom;
    }
  }
  .checkbox.checkbox {
    label {
      color: $color-apple-blossom;
    }
  }
}

input[type="submit"] {
  &.btn {
    font-size: 14px;
    font-weight: 500;
    padding: 18px 16px 12px;
    text-transform: uppercase;
  }
}

.help-block {
  text-align: left;

  .text-danger {
    font-size: 14px;
  }
}

.link-alignment {
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin: 5px 0 10px;

  .help-block {
    margin: 0;
  }
}

.error-message {
  color: $color-apple-blossom;
  display: block;
  font-size: 14px;
  font-style: italic;
  margin-bottom: 10px;
  margin-top: 5px;
  text-align: left;
}

.react-code-input > input {
  outline: none;
  padding: 0 !important;
  text-align: center;
}

select {
  &.form-control {
    cursor: pointer;
  }

  &.default {
    height: 44px;
  }
}

.form-control {
  &.default-height {
    height: 44px;
  }
}
