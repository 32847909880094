#header {
  border-bottom: 2px solid $color-orange;
  padding: 20px;
  position: relative;
  text-align: center;

  @include breakpoint($mobile) {
    padding: 10px;
  }

  .logo-header {
    @include inline-block(top);
    padding: 2px;
    width: 130px;

    &.w-250 {
      width: 250px;

      @include breakpoint($mobile) {
        max-width: 200px;
        width: 55%;
      }
    }

    @include breakpoint($mobile) {
      width: 120px;
    }

    a {
      display: block;
    }
  }

  &.self-service-header {
    background: $color-white;
    margin: 0;
    padding: 17px 20px;
    text-align: center;

    .mobile.keyboard-shown & {
      position: relative !important;
    }

    @include breakpoint($mobile) {
      padding: 10px 5px 3px 5px;
    }

    .logo-header {
      left: 20px;
      position: absolute;

      &.w-250 {
        @include breakpoint(880px) {
          width: 200px;
        }
      }
      @include breakpoint(768px) {
        display: none;
      }
    }
  }
}
