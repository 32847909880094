$btn-padding-y: 13px;
$btn-padding-x: 15px;

$btn-padding-y-mobile: 14px;
$btn-padding-x-mobile: $btn-padding-x;

.btn {
  border-width: 0;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: $btn-padding-y $btn-padding-x;
  text-align: center;
  white-space: normal;
  @include breakpoint($mobile) {
    padding: $btn-padding-y-mobile $btn-padding-x-mobile;
  }

  &:active,
  &.focus,
  &:focus,
  &.active.focus,
  &:active.focus,
  &.active:focus,
  &:active:focus {
    box-shadow: none;
    outline: none;
  }

  &.in-progress {
    position: relative;

    &:before {
      -moz-animation: spin 0.6s linear 300;
      -webkit-animation: spin 0.6s linear 300;
      animation: spin 0.6s linear 300;
      border: 2px solid rgba(255, 255, 255, 0.15);
      border-radius: 100%;
      border-top-color: rgba(255, 255, 255, 0.8);
      box-sizing: border-box;
      content: "";
      display: block;
      height: 25px;
      left: 25px;
      margin-left: -12.5px;
      margin-top: -12.5px;
      position: absolute;
      top: 50%;
      width: 25px;
      z-index: 1;
    }
  }

  &-text {
    background: none;
    border: none;
  }

  &-reset {
    align-items: center;
    background: none;
    border: solid 1px $color-dusty-gray;
    border-radius: 22px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    font-size: 10px;
    font-weight: 900;
    justify-content: space-between;
    padding: 4px 11px 4px 12px;
    text-align: left;
    z-index: 2;

    @include breakpoint($mobile) {
      min-width: 0;
      padding: 4px 8px;
    }

    &:hover {
      background-color: $color-concrete;
    }

    .icon {
      color: $color-dusty-gray;
      font-size: 16px;
    }

    span:not(.inline) {
      color: $color-greeny-keppel;
      line-height: 1.2;
      margin: 0 0 0 6px;
    }
  }

  &-square {
    border-radius: 0;
  }

  &-pill {
    border-radius: 1000em;
    padding-left: 1.25em;
    padding-right: 1.25em;
  }

  &-width-a {
    width: auto;
  }

  // stylelint-disable-next-line
  &-text {
    background: none;
  }
}

.btn-default.focus,
.btn-default:focus {
  background: none;
}

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-success {
  color: #fff;
  background-color: #43b392;
  border-color: #43b392;
}

.btn-success:hover {
  color: #fff;
  background-color: #358e74;
  border-color: #32866e;
}

.btn-default {
  color: #9b9b9b;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default:hover {
  color: #9b9b9b;
  background-color: #e6e6e6;
  border-color: #adadad;
}



.btn-back,
.btn-back:active {
  background: url("/assets/img/icons/left-teal.png") 0 8px no-repeat;
  color: $color-dusk;
  padding-left: 25px;
  padding-right: 0;
}

.btn-back.back-icon {
  background: none;
  position: relative;

  .icon {
    font-size: 20px;
    left: 0;
    position: absolute;
    top: 8px;
  }
}

.question-btn {
  color: $color-greeny-keppel;
  height: 30px;
  position: absolute;
  right: 19px;
  top: 18px;
  width: 30px;

  /* stylelint-disable-next-line */
  img {
    margin: 0 !important;
  }

  &:hover {
    opacity: 0.6;
  }
}

.btn-file {
  overflow: hidden;
  position: relative;

  input[type="file"] {
    background: white;
    cursor: inherit;
    display: block;
    filter: alpha(opacity=0);
    font-size: 100px;
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    outline: none;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
  }
}
