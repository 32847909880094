// stylelint-disable
.modal-dialog {
  @include breakpoint($mobile-xs) {
    margin: 15px;
  }

  &:not(.modal-styled) {
    .modal-content {
      border-radius: 10px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 15px;
        margin-top: 0;
      }
    }
  }
}

.modal-wo-title {
  .modal-header {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .modal-body {
    padding: 0 20px 30px;
  }

  h3 {
    margin: 0;
  }

  select {
    margin: 0 auto;
    width: 140px;
  }
}

.notes-modal {
  &.how-to-pause {
    margin-top: 100px;
  }

  .modal-header {
    border-bottom: 0;
    padding: 22px 23px;
    padding-bottom: 0;
  }

  .close {
    color: $color-white;
    font-size: 30px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  .modal-body {
    font-size: 14px;
    padding: 4px 30px 30px;
  }

  .modal-content {
    background-color: $color-dusk;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    color: $color-white;
  }

  h3,
  h5 {
    color: $color-white;
    margin-top: 0;
  }

  h5 {
    font-size: 20px;
    margin-bottom: 24px;
  }

  .btn-default,
  .btn-default:active {
    color: $color-dusk;
  }
}

.covers-list {
  margin: 0 0 38px;
  padding-top: 48px;
  text-align: left;

  li {
    box-shadow: inset 0 -1px 0 0 $color-ghost;
    color: $color-dusk;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    font-size: 14px;
    font-weight: 900;
    justify-content: space-between;
    margin: 0 0 18px;
    padding: 0 0 7px;
  }

  .cover-limit {
    color: $color-sandy-brown;
    float: right;
    font-size: 18px;
    font-weight: 900;
  }
}

.total-price {
  font-size: 40px;
  font-weight: 900;
}

// Styles for this default popup  https://app.zeplin.io/project/59e89a9160c5ee800fe76c81/screen/5a0ae1d25e50cb72dcb3f684
.modal.modal-default {
  .modal-content {
    max-width: 400px;
    padding: 40px 50px;
    width: 100%;
  }

  &.modal-w-450 {
    .modal-content {
      max-width: 450px;
    }
  }

  &.modal-padding-20 {
    .modal-content {
      padding: 20px;
    }
  }

  .modal-header {
    padding: 0;
  }

  .modal-body {
    color: $color-dove-gray;
    padding: 0;
  }

  .modal-title {
    font-size: 22px;
  }

  .modal-footer {
    margin: auto;
    padding: 15px 15px 0;
    width: 200px;
  }
}

body > div[role="dialog"] + div[role="dialog"] {
  .modal-backdrop {
    z-index: 1050;
  }
}

.reset-modal-open {
  .btn-close {
    position: absolute;
    right: 12px;
    top: 10px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 0.2;
  }
  .modal-header {
    display: block;
  }
  .modal-footer {
    display: block;
  }
  .start-over-btn {
    color: #fff;
    background-color: #43b392;
    border-color: #43b392;
    width: 100%;
    &:hover {
      color: #fff;
      background-color: #358e74;
      border-color: #32866e;
    }
  }
  .cancel {
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 5px;
    color: #9b9b9b;
    background-color: #fff;
    border-color: #ccc;
    width: 100%;
    &:hover {
      color: #9b9b9b;
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }
}
