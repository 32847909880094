.react-datepicker {
  background-color: $color-wild-sand;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
  margin: 0 auto 40px;

  &__portal {
    flex-direction: column;
  }

  .close-btn-container {
    border-top: 1px solid $color-alto;
    clear: both;
    overflow: hidden;
    padding: 0;

    .button-group {
      padding: 0;
    }

    .btn-text {
      color: $color-greeny-keppel;
      font-weight: 700;
      width: auto;

      &:hover{
        background-color: #e6e6e6;
        border-color: #adadad;
      }
    }

    .btn-text-red {
      background: none;
      border: none;
      color: $color-kraiola-mahogany;
      font-weight: 700;
    }
  }

  &.has-error:not(.col-xs-6) {
    padding: 0;

    .datepicker-area {
      border: 1px solid $color-red;
      padding: 8px 9px 11px 9px;
    }
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__navigation {
    position: absolute;
    top: 32px;
  }
}

// stylelint-disable-next-line
.datepicker-area {
  align-items: center;
  background-color: $color-wild-sand;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 14px;
  justify-content: space-between;
  letter-spacing: -0.05px;
  padding: 9px 10px 12px 10px;
  text-transform: none;

  // stylelint-disable-next-line
  &:focus {
    background-color: $color-wild-sand;
    box-shadow: none;
  }

  button:not(.close-btn) {
    background: none;
    border: none;
    padding: 0;

    &:focus {
      outline: none;
    }
  }

  > div {
    position: absolute;
  }

  .icon-area {
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;

    img {
      @include inline-block(top);
      margin-right: 7px;
      margin-top: 3px;
      width: 18px;
    }
  }

  .value-area {
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;

    span {
      display: block;
      margin: 7px 6px 0 0;
    }

    // stylelint-disable-next-line
    button {
      margin: 3px 46px 0 0;

      @include breakpoint($mobile) {
        margin: 3px 10px 0 0;
      }
    }

    img {
      display: block;
      margin-top: 3px;
    }
  }

  .text {
    color: $color-dusk;
  }

  h6 {
    line-height: 14px;
    margin: 9px 0 5px;
  }

  // stylelint-disable-next-line
  > span {
    color: $color-dove-gray;
  }
}

.datepicker-holder {
  @include breakpoint($mobile-xs) {
    padding: 0 15px;
  }
}

// stylelint-disable-next-line
.react-datepicker {
  width: 340px;

  @include breakpoint($mobile-xs) {
    width: 280px;
  }

  .react-datepicker__month-container {
    width: 100%;

    // stylelint-disable
    .react-datepicker__day,
    .react-datepicker__day-name,
    .react-datepicker__time-name {
      // stylelint-enable
      color: $color-dove-gray;
      font-size: 14px;
      height: 40px;
      line-height: 30px;
      margin: 0;
      padding-top: 4px;
      width: 40px !important;

      @include breakpoint($mobile-xs) {
        height: 38px;
        width: 38px !important;
      }
    }
  }

  .react-datepicker__month {
    min-height: 250px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker {
    border: none;
  }

  .react-datepicker__today-button {
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.01), inset 0 1px 0 0 $color-iron;
  }

  .react-datepicker__header,
  .react-datepicker__today-button {
    background: none;
    border: none;
  }

  .react-datepicker__header {
    padding-top: 25px;
  }

  .react-datepicker__day-names {
    .react-datepicker__day-name {
      color: $color-dusty-gray;
      font-size: 10px;
      font-weight: 900;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  .react-datepicker__current-month {
    color: $color-dusk;
    font-size: 20px !important;
    margin-bottom: 10px;
  }

  // stylelint-disable-next-line
  .react-datepicker__today-button {
    color: $color-greeny-keppel;
    font-size: 14px;
    letter-spacing: 0.5px;
    padding: 15px 0;
  }

  .react-datepicker__day.react-datepicker__day--in-range {
    background-color: $color-silver !important;
  }

  .react-datepicker__day.react-datepicker__day--in-selecting-range {
    background-color: $color-iron !important;

    &:hover {
      background-color: $color-greeny-keppel !important;
    }
  }

  .react-datepicker__day.react-datepicker__day--selected,
  .react-datepicker__day.react-datepicker__day--range-end,
  .react-datepicker__day--keyboard-selected {
    background-color: $color-greeny-keppel !important;
    color: $color-white !important;
  }

  .react-datepicker__day--disabled {
    opacity: 0.4;
  }
}

.datepicker {
  margin: 0 auto 40px;

  &.has-error:not(.col-xs-6) {
    padding: 0;

    .datepicker-area {
      border: 1px solid $color-red;
      padding: 8px 9px 11px 9px;
    }
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__navigation {
    position: absolute;
    top: 32px;
  }
  // stylelint-disable-next-line
  .datepicker-area {
    align-items: center;
    background-color: $color-wild-sand;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    font-size: 14px;
    justify-content: space-between;
    letter-spacing: -0.05px;
    padding: 9px 10px 12px 10px;
    text-transform: none;

    // stylelint-disable-next-line
    &:focus {
      background-color: $color-wild-sand;
      box-shadow: none;
    }

    button:not(.close-btn) {
      background: none;
      border: none;
      padding: 0;

      &:focus {
        outline: none;
      }
    }

    > div {
      position: absolute;
    }

    .icon-area {
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;

      img {
        @include inline-block(top);
        margin-right: 7px;
        margin-top: 3px;
        width: 18px;
      }
    }

    .value-area {
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;

      span {
        display: block;
        margin: 7px 6px 0 0;
      }

      // stylelint-disable-next-line
      button {
        margin: 3px 46px 0 0;

        @include breakpoint($mobile) {
          margin: 3px 10px 0 0;
        }
      }

      img {
        display: block;
        margin-top: 3px;
      }
    }

    .text {
      color: $color-dusk;
    }

    h6 {
      line-height: 14px;
      margin: 9px 0 5px;
    }

    // stylelint-disable-next-line
    > span {
      color: $color-dove-gray;
    }
  }

  .react-datepicker {
    background-color: $color-wild-sand;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
    font-size: 14px;
  }
}

.side-modal {
  .react-datepicker__portal {
    width: 100%;
  }
}
