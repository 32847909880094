.redux-toastr {
  .toastr {
    min-height: 50px;

    .close-toastr {
      font-size: 18px;
    }

    &.rrt-success {
      background-color: $color-greeny-keppel;
    }

    .rrt-middle-container {
      margin: 0;
      width: 100%;

      .rrt-holder {
        left: 3px;
        margin-top: -24px;
        width: 49px;
      }
    }

    .rrt-left-container {
      display: none;
    }
    .rrt-title {
      font-size: 14px !important;
      font-weight: normal !important;
      margin: 7px 22px 0;
    }
  }
}
