// icons
$icon-font-path: "../../fonts/";
$icon-font-name: "toolkit-entypo";

// Fonts
$base-font-sans-serif: Arial, "Helvetica Neue", Helvetica, sans-serif;

$font-family-sans-serif: "Avenir", "Arial", "Helvetica Neue", "Helvetica", sans-serif;

$font-ave: "Avenir", $base-font-sans-serif;
$font-ave-roman: "Avenir-Roman", "Avenir", $base-font-sans-serif;
$font-ave-book: "Avenir-Book", $base-font-sans-serif;
$font-ave-book-oblique: "Avenir-BookOblique", $base-font-sans-serif;
$font-ave-black: "Avenir-Black", $base-font-sans-serif;
$font-ave-black-oblique: "Avenir-BlackOblique", $base-font-sans-serif;

$font-size-base:  1rem !default;
$line-height-base: 1.2 !default;

// Body
$base-text-color: $color-white !default;
$base-background-color: $color-ebony-clay !default;

$base-min-width: 320px;
$mobile-xs: 374px;

$animation-speed: 0.3s;

$brand-primary: $color-orange;
$brand-success: $color-greeny-keppel;
$brand-info: $color-pale-slate;
$brand-warning: $color-orange;
$brand-danger: $color-flame-pea;

$btn-default-color: $color-dusty-gray;

$btn-success-border: $brand-success;

//** Background color for `<body>`.
$body-bg: $color-white;

//** Global text color on `<body>`.
$text-color: $color-dusk;
$link-color: $color-dusk;
$state-success-text: $brand-success;
$text-muted: $color-dusty-gray;

//** Global textual link color.
$link-color: $brand-primary;
//** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%);
//** Link hover decoration.
$link-hover-decoration: underline;

$headings-font-weight: 900;
$headings-color: $color-dusk;

//FORMS
$input-border: $color-greeny-keppel;
$input-border-focus: darken($color-greeny-keppel, 10%);
$input-bg-disabled: lighten($color-greeny-keppel, 60%);
$input-color-placeholder: $color-dusty-gray;
$input-height-base: 44px;
$input-group-addon-bg: $color-white;

$border-radius-base: 5px;

//Custom select
$custom-select-color: $color-greeny-keppel;
$custom-select-bg: $color-white;
$custom-select-disabled-bg: $color-white;

// heading element
$h5-font-size:1rem;
