.nav-tabs.nav-justified {
  li > a {
    border-bottom: 1px solid $color-alto;
    border-radius: 0;
  }

  .active > a {
    border: none;
    box-shadow: inset 0 -2px 0 0 $color-greeny-keppel;
    color: $color-dusk;

    &:hover,
    &:focus,
    &:active {
      border: none;
      box-shadow: inset 0 -2px 0 0 $color-greeny-keppel;
      color: $color-dusk;
    }
  }
}

.nav-tabs {
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 36px;

  // stylelint-disable-next-line
  li > a {
    border-radius: 0;
    color: $color-dusty-gray;
  }
}

.nav-justified > li,
.nav-tabs.nav-justified > li {
  display: table-cell;
  width: 1%;
}
