.btn-loading {
  &:before {
    animation: spin 0.6s linear 300;
    border: 2px solid rgba(67, 179, 146, 0.15);
    border-radius: 100%;
    border-top-color: rgba(67, 179, 146, 0.8);
    box-sizing: border-box;
    content: "";
    display: block;
    height: 25px;
    left: 12px;
    margin-top: -12.5px;
    position: absolute;
    top: 50%;
    width: 25px;
    z-index: 1;
  }
}
