footer {
  color: $color-dusk;
  text-align: center;

  @include breakpoint($mobile) {
    margin: 0;
  }

  &.sticky {
    background-color: $color-wild-sand;
    bottom: 0;
    box-shadow: inset 0 1px 0 0 $color-ghost;
    left: 0;
    line-height: 70px;
    padding: 14px 0;
    position: fixed;
    width: 100%;
    z-index: 10;

    .btn-set {
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      margin: auto;
      max-width: 670px;
      padding: 0 15px;

      @include breakpoint($mobile) {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
      }

      button {
        margin: 0 15px;

        @include breakpoint($mobile) {
          margin: 0 0 5px;
        }
      }
    }

    .btn {
      margin: 0;
    }
  }

  &.main-footer {
    background-color: $color-alabaster;
    border: 1px solid $color-mercury;
    border-radius: 0;
    color: $color-boulder;
    font-family: $font-ave-book;
    font-size: 8px;
    min-height: 50px;
    padding: 15px;
    position: relative;

    &.full-width {
      width: 100vw;
    }

    &.grey-14 {
      color: #333;
      font-size: 12px;
      min-height: unset;
    }

    @include breakpoint($mobile) {
      min-height: auto;
    }
  }

  .footer-frame {
    margin: 0 auto;
    max-width: 856px;
  }
}
