// breakpoints
$mobile: 600px; // mobile 600px and less
$tablet: 1023px; // tablet 1023px {desktop + 1} and less

// breakpoints mixin
// @include breakpoint($mobile) {}
@mixin breakpoint($rule) {
  @media only screen and (max-width: #{$rule}) {
    @content;
  }
}

// @include border-radius(5px);
@mixin border-radius($border-width: 5px) {
  border-radius: $border-width;
}

//@include inline-block(top);
@mixin inline-block($align:top) {
  display: inline-block;
  vertical-align: $align;
}

//@extend %clearfix;
// clearfix
%clearfix {
  &:after {
    clear: both;
    content: " ";
    display: block;
  }
}

//@extend %listreset;
// reset for list
%listreset {
  list-style: none;
  margin: 0;
  padding: 0;
}

// vertical align el inside parent with fixed height/min-height
// usage
// html - .parent>.child
// scss - @include v-align;
//     or @include v-align(250px);
//     or @include v-align(250px, bottom, before);
//
@mixin v-align($va-height: 100%, $va-direction: middle, $va-pseudo: after) {
  text-align: center;
  white-space: nowrap;

  &:#{$va-pseudo} {
    content: "";
    display: inline-block;
    min-height: $va-height;
    vertical-align: $va-direction;
    width: 0;
  }

  > * {
    display: inline-block;
    max-width: 99%;
    vertical-align: $va-direction;
    white-space: normal;
  }
}

// vertical align a pair of child el inside parent
// usage
// html - .post>.image+.text
// scss - @include v-align-pair(image, text);
//     or @include v-align-pair(image, text, bottom);
//     or @include v-align-pair(image, text, middle, 200px);
//
@mixin v-align-pair($child-name1, $child-name2, $valign: middle, $width1: auto, $width2: auto) {
  display: table;

  .#{$child-name1} {
    display: table-cell;
    vertical-align: $valign;
    width: $width1;
  }

  .#{$child-name2} {
    display: table-cell;
    vertical-align: $valign;
    width: $width2;
  }
}

// vertical align el
// usage: @include vertical-align-el;
//
@mixin vertical-align-el {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

// @include prefix(transform, translateY(-50%));
// @include prefix(transition, (all 0.3s linear));
@mixin prefix($name, $argument) {
  #{$name}: $argument;
  -webkit-#{$name}: $argument;
  -ms-#{$name}: $argument;
  -moz-#{$name}: $argument;
  -o-#{$name}: $argument;
}

/// font-smothing
/// @include font-smoothing(on);
/// @include font-smoothing(off);
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

/// Hides the text in an element, commonly used to show an image. Some elements will need block-level styles applied.
///
/// @link http://zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement
///
/// @example scss - Usage
///   .element {
///     @include hide-text;
///   }
///
/// @example css - CSS Output
///   .element {
///     overflow: hidden;
///     text-indent: 101%;
///     white-space: nowrap;
///   }
///
@mixin hide-text {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}

/// Animate css properties
// usage
// scss - @include animate(color);
//     or @include animate(color width);
//     or @include animate(color width, 1s, linear);
//
$animation-speed: 0.3s !default;

@mixin animate($properties, $duration: $animation-speed, $easing: ease-in-out) {
  $list: ();
  @each $prop in $properties {
    $str: #{$prop} #{$duration} #{$easing};
    $list: join($list, #{$str}, comma);
  }
  transition: $list;
}

/// Font-size px to rem calc
/// @example scss - Usage
/// selector {
///    @include rem-font-size(16px);
/// }

@mixin rem-font-size($px-size) {
  font-size: $px-size;
  font-size: ($px-size / 10px) + rem;
}

// @include fonts-include(Avenir, Avenir-Light, font-weight, font-style);
@mixin fonts-include($font-name, $font-path, $font-weight, $font-style) {
  @font-face {
    font-family: "#{$font-name}";
    font-style: $font-style;
    font-weight: $font-weight;
    src: url("../../fonts/#{$font-path}.eot");
    src: url("../../fonts/#{$font-path}.eot?#iefix") format("embedded-opentype"),
      url("../../fonts/#{$font-path}.woff") format("woff"), url("../../fonts/#{$font-path}.woff2") format("woff2"),
      url("../../fonts/#{$font-path}.ttf") format("truetype"),
      url("../../fonts/#{$font-path}.svg##{$font-name}") format("svg");
  }
}
