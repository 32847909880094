@keyframes grow-vertical {
  from {
    height: 0;
    margin-top: 0;
  }
  to {
    height: 250px;
  }
}

.grow-vertical {
  animation: grow-vertical cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s forwards;
  animation-delay: 0.1s;
}

.address-container {
  position: relative;
  z-index: 2;

  #cc_c2a {
    border: 0 !important;
    left: 0 !important;
    overflow: hidden !important;
    right: 0;
    top: 48px !important;
    width: auto !important;
    z-index: 100 !important;

    .mainbar {
      display: none;
    }

    .country_btn {
      display: none;
    }

    .c2a_light {
      background: none;
      border: none;
    }
  }
}
