@import "mixins";

// Avenir (Normal)
// ====================================
@include fonts-include(Avenir, Avenir-Light, 300, normal);
@include fonts-include(Avenir, Avenir-Roman, normal, normal);
@include fonts-include(Avenir, Avenir-Medium, 500, normal);
@include fonts-include(Avenir, Avenir-Medium, 600, normal);
@include fonts-include(Avenir, Avenir-Heavy, 900, normal);

// Avenir (Italic)
// ====================================
@include fonts-include(Avenir, Avenir-LightOblique, 300, italic);
@include fonts-include(Avenir, Avenir-Oblique, normal, italic);
@include fonts-include(Avenir, Avenir-MediumOblique, 500, italic);
@include fonts-include(Avenir, Avenir-HeavyOblique, 900, italic);

// Avenir Book (Normal)
// ====================================
@include fonts-include(Avenir-Book, Avenir-Book, normal, normal);

// Avenir Book (Italic)
// ====================================
@include fonts-include(Avenir-BookOblique, Avenir-BookOblique, normal, italic);

// Avenir Black (Normal)
// ====================================
@include fonts-include(Avenir-Black, Avenir-Black, 900, normal);

// Avenir Black (Italic)
// ====================================
@include fonts-include(Avenir-BlackOblique, Avenir-BlackOblique, 900, italic);

// Avenir (Normal) for Windows
// ====================================
@include fonts-include(Avenir, Avenir-Medium, 700, normal);
