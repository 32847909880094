// list reset
%listreset {
  list-style: none;
  margin: 0;
  padding: 0;
}

// clearfix
%clearfix {
  &:after {
    clear: both;
    content: "";
    display: block;
  }
}

.clearfix {
  @extend %clearfix;
}

// transition
%transition {
  transition: all 0.3s ease-in-out;
}

// justify nav
%justify {
  font-size: 1px;
  line-height: 0;
  text-align: justify;

  > * {
    @include inline-block(top);
    font-size: $font-size-base;
    line-height: $line-height-base;
    text-align: left;
  }

  &:after {
    content: "";
    width: 100%;
    @include inline-block(top);
  }
}

/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis; /* 2 */
  white-space: nowrap; /* 1 */
}

.nowrap {
  white-space: nowrap;
}

.dib {
  display: inline-block !important;
  vertical-align: top;
}

.d-inline {
  display: inline;
}

.d-block {
  display: block;
}

.width-auto {
  width: auto;
}

.less-padding {
  padding-left: 5px;
  padding-right: 5px;
}

.less-padding-xs {
  padding-left: 5px;
  padding-right: 5px;

  .thumbnail-circle {
    padding: 0 10px;
  }
}

.less-padding-left {
  padding-left: 5px;
}

.less-padding-right {
  padding-right: 5px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

//margins
.mt-0 {
  margin-top: 0;
}

.m0 {
  margin: 0 !important;
}
.ma {
  margin: 0 auto;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt25 {
  margin-top: 25px !important;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mt50 {
  margin-top: 50px;
}
.mt100 {
  margin-top: 100px;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb35 {
  margin-bottom: 35px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb60 {
  margin-bottom: 60px;
}
.mb100 {
  margin-bottom: 100px;
}
.mb90 {
  margin-bottom: 90px;
}
.mb50 {
  margin-bottom: 50px;
}
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr20 {
  margin-right: 20px;
}

.mt-20 {
  margin-top: -20px;
}
.mt-30 {
  margin-top: -30px;
}
.mt-40 {
  margin-top: -40px;
}

//padding
.p0 {
  padding: 0 !important;
}
.pt0 {
  padding-top: 0;
}
.pl0 {
  padding-left: 0;
}
.pr0 {
  padding-right: 0;
}
.pb0 {
  padding-bottom: 0;
}
.plr5 {
  padding-left: 5px;
  padding-right: 5px;
}
.pl13 {
  padding-left: 13px;
}

.pl20 {
  padding-left: 20px;
}
.pl30 {
  padding-left: 30px !important;
}
.pl40 {
  padding-left: 40px;
}
.pb50 {
  padding-bottom: 50px;
}
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
.pt10 {
  padding-top: 10px !important;
}
.pt40 {
  padding-top: 40px;
}

// old styles

.fs14 {
  font-size: 14px;
}

.fs18 {
  font-size: 18px;
}

.fs-24 {
  font-size: 24px;
}

.orange-text {
  color: $color-orange;
}

.inline-item {
  display: inline-block;
}

.clipped-overflow {
  overflow: hidden;
}

.relative {
  position: relative;
}

.clickable {
  cursor: pointer;
}

.min-width {
  min-width: 220px;
}

.align-justify-between {
  display: flex;
  justify-content: space-between;
}
